import React from 'react'

const Footer = () => {
	return (
		<footer className="footer-container">
			<div className="footer-content">
				<p class="resume">
					<a href="https://drive.google.com/file/d/10Ah-_meW_aMYPWMSjLzZpR3y19RoBQR4/view?usp=drive_link" target="_blank" rel="noopener noreferrer">Download resume</a>
				</p>
			</div>
    	</footer>
	);
};

export default Footer;