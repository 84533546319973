import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faX } from '@fortawesome/free-solid-svg-icons';

const InfoCard = () => {
	return (
		<div id="info-card">
			<img id="avatar" src="https://media.licdn.com/dms/image/v2/D4E03AQHW5fSLBuMMXg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1723918617451?e=1738800000&v=beta&t=l0xYhKofRQeW4q6GJC_dOecPDG-c7SVX_19K7jbY5Cw"></img>
			<div class="info">
				<h1>Bilal Gannouni</h1>
				<h2>Software Developer</h2>
				<h3>⚲ Martil, Morocco</h3>
				<div className="social-media-links">
					<a href="mailto:bilal.gannouni.00@gmail.com" className="social-icon">
						<FontAwesomeIcon icon={faEnvelope} />
					</a>
					<a href="" className="social-icon">
						<FontAwesomeIcon icon={faX} />
					</a>
					<a href="tel:+212658963431" target="_blank" rel="noopener noreferrer" className="social-icon">
        				<FontAwesomeIcon icon={faPhone} />
      				</a>
					<a href="https://linkedin.com/in/bilal-gannouni" target="_blank" rel="noopener noreferrer" className="social-icon">
						<FontAwesomeIcon icon={faLinkedin} />
					</a>
					<a href="https://github.com/U81K" target="_blank" rel="noopener noreferrer" className="social-icon">
						<FontAwesomeIcon icon={faGithub} />
					</a>
					</div>
			</div>
			<div class="about">
				<h2 class="aboutTitle">About</h2>
				<h3 class="aboutText">Hi, I'm Bilal Gannouni, a passionate software developer with experience in building low-level applications, solving complex problems, and continuously learning new technologies. </h3>
			</div>
		</div>
	);
};

export default InfoCard;