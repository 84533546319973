export const projects = [
	{
	  title: 'ft_transcendence',
	  description: 'A full-stack web application featuring a 2D Ping Pong game',
	  details: [
		'built with Django (as a single-page application) for the backend and HTML, CSS, and vanilla JavaScript for the frontend.',
		'offers real-time features such as matchmaking and chat.',
	  ],
	  github: 'https://github.com/amindid/WePong',
	},
	{
	  title: 'Webserv',
	  description: 'An HTTP server implemented in C++98, offering basic functionality similar to popular web servers like NGINX.',
	  details: [
		'support methods like GET, POST, and DELETE',
		'accept file uploads, and manage CGI execution (e.g., for PHP or Python scripts).',
	  ],
	  github: 'https://github.com/U81K/Webserv',
	},
	{
	  title: 'Inception',
	  description: 'Docker-based infrastructure on a virtual machine',
	  details: [
		'featuring NGINX with TLS, WordPress with PHP-FPM, and MariaDB',
		'Each service runs in its own container, orchestrated by Docker Compose.',
	  ],
	  github: 'https://github.com/U81K/inception',
	},
	{
	  title: 'Minishell',
	  description: 'The Minishell project involves creating a simple shell in C, mimicking basic Bash functionality',
	  details: [
		'Supports built-in commands like echo, cd, pwd, export, unset, env, and exit.',
		'Handles command execution, pipes, redirections, signal handling.',
	  ],
	  github: 'https://github.com/youssef-bourais/MINISHELL_42',
	},
	{
	  title: 'Personal Portfolio',
	  description: 'Single-page portfolio built with React',
	  details: [
		'Responsive design using CSS and Flexbox for layout.',
	  ],
	  github: 'https://www.github.com/',
	},
  ];