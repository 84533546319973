import { FaHtml5, FaCss3Alt, FaJs, FaGit, FaPython, FaDocker, FaCode, FaCogs} from 'react-icons/fa';
import { SiReact } from 'react-icons/si';

export const skills = [
	{ name: 'C', icon: <FaCode /> },
	{ name: 'C++', icon: <FaCogs /> },
	{ name: 'JavaScript', icon: <FaJs /> },
	{ name: 'React', icon: <SiReact /> },
	{ name: 'HTML', icon: <FaHtml5 /> },
	{ name: 'CSS', icon: <FaCss3Alt /> },
	{ name: 'Git', icon: <FaGit /> },
	{ name: 'Python (Django Rest Framework)', icon: <FaPython /> },
	{ name: 'Docker', icon: <FaDocker /> },
];
