import React from 'react'
import { FaGraduationCap } from 'react-icons/fa';

const Education = () => {
	return (
		<div id="education-card">
			<h2 class="aboutTitle">Education</h2>
			<div class="uni-card">
				<div class="grad-icon"><FaGraduationCap /></div>
				<h3 class="project-title">1337 Coding school</h3>
				<div class="project-details">
					2022 - Present
				</div>
			</div>
			<div class="project-details uni">
				Computer Science
			</div>
			<div class="uni-card">
				<div class="grad-icon"><FaGraduationCap /></div>
				<h3 class="project-title">Faculty of Juridical, Economic and Social science</h3>
				<div class="project-details">
					2019 - 2023
				</div>
			</div>
			<div class="project-details uni">
				Economic science and Gestion
			</div>
			<div class="empty"></div>
		</div>
	);
};

export default Education;