import React from 'react'
import { skills } from '../data/skills';

const Skills = () => {
	return (
		<div id="skills-container">
			<h2 class="aboutTitle">Skills</h2>
			<ul className="skills-list">
				{skills.map((skill, index) => (
					<li key={index} className="skills-item">
						<div className="skills-icon">{skill.icon}</div>
						<span className="skills-name">{skill.name}</span>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Skills;