import React from 'react';
import InfoCard from '../components/info_card'
import ProjectCard from '../components/ProjectCard';
import Skills from '../components/Skills';
import Education from '../components/Education';
import Footer from '../components/Footer';
import ContactCard from '../components/ContactCard'

const Home = () => {
	return (
		<div id="main">
			<InfoCard />
			<div id="rest">
				<ProjectCard />
				<Skills />
				<Education />
				{/* <ContactCard /> */}
			</div>
			<Footer />
		</div>
	);
}

export default Home;