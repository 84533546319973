import React from 'react';
import { FaFolder, FaGithub } from 'react-icons/fa';
import {projects} from '../data/projects'

const ProjectCard = () => {
  return (
    <div className="project-card-container">
		<h2 class="aboutTitle">Projects</h2>
		<div className="project-cards">
        {projects.map((project, index) => (
          <div key={index} className="card">
            <div className="card-header">
              <FaFolder className="folder-icon" />
              <h3 className="project-title">{project.title}</h3>
              <a href={project.github} target="_blank" rel="noopener noreferrer">
                <FaGithub className="github-icon" />
              </a>
            </div>
            <p className="project-description">{project.description}</p>
            <ul className="project-details">
              {project.details.map((detail, idx) => (
                <li key={idx}>{detail}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectCard;
